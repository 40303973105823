import { useHistory, useParams } from "react-router-dom";
import useFetch from "./useFetch";

const BlogDetails = () => {
    const { id } = useParams();
    const {
        data: blog,
        error,
        isPending,
    } = useFetch("https://jsonserver.online/user/mri-QlJ-dcF/blogs//" + id);
    const history = useHistory();

    const handleClick = () => {
        fetch("https://jsonserver.online/user/mri-QlJ-dcF/blogs//" + blog.id, {
            method: "DELETE",
        }).then(() => {
            history.push("/");
        });
    };

    return (
        <div className="container">
            <div className="blog-details">
                <p>Blog Details - {id}</p>
                {isPending && <div>Loading...</div>}
                {error && <div>{error}</div>}
                {blog && (
                    <article>
                        <h2 className="title">{blog.title}</h2>
                        <p className="body">{blog.body}</p>
                        <p className="author">Author : {blog.author}</p>
                    </article>
                )}
                <button className="btn" onClick={handleClick}>
                    Delete
                </button>
            </div>
        </div>
    );
};

export default BlogDetails;
