import { useState } from "react";
import { useHistory } from "react-router-dom";

const Create = () => {
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [author, setAuthor] = useState("");
    const [isPending, setIsPending] = useState(false);
    const history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();
        const blog = { title, body, author };

        setIsPending(true);

        fetch("https://jsonserver.online/user/mri-QlJ-dcF/blogs/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(blog),
        }).then(() => {
            console.log("success");
            // history.go(-1);
            history.push("/");
        });
    };

    return (
        <div className="container">
            <div className="create">
                <p>Add A New Blog</p>
                <form onSubmit={handleSubmit}>
                    <div className="input-field">
                        <label>Blog Title</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-field">
                        <label>Blog Body</label>
                        <textarea
                            onChange={(e) => setBody(e.target.value)}
                            required></textarea>
                    </div>
                    <div className="input-field">
                        <label>Blog Author</label>
                        <select onChange={(e) => setAuthor(e.target.value)}>
                            <option value="A">Apple</option>
                            <option value="B">Boy</option>
                            <option value="C">Cat</option>
                        </select>
                    </div>
                    {!isPending && (
                        <button className="btn-submit" type="submit">
                            Submit
                        </button>
                    )}
                    {isPending && (
                        <button className="btn-submit" disabled>
                            Thank you for your submittion
                        </button>
                    )}
                </form>
                <p>{title}</p>
                <p>{body}</p>
                <p>{author}</p>
            </div>
        </div>
    );
};

export default Create;
