import { Link } from "react-router-dom";

const BlogList = ({ blogs, title, handleDelete }) => {
    return (
        <div className="blogList">
            <p className="title">{title}</p>
            {blogs.map((blog) => (
                <div className="blog" key={blog.id}>
                    <p className="title">{blog.title}</p>
                    <p className="body">{blog.body}</p>
                    <p className="author">{blog.author}</p>
                    {/* <button onClick={() => handleDelete(blog.id)}>Delete</button> */}
                    <Link className="btn" to={`/blogs/${blog.id}`}>
                        More
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default BlogList;
