import useFetch from "./useFetch";
import BlogList from "./BlogList";

const Home = () => {
    const {
        data: blogs,
        isPending,
        error,
    } = useFetch("https://jsonserver.online/user/mri-QlJ-dcF/blogs//");

    return (
        <div className="container">
            <h1 className="pageTitle">Welcome to My Hole</h1>
            {error && <p>{error}</p>}
            {isPending && <p>Loading...</p>}
            {blogs && <BlogList blogs={blogs} title="All Blogs"></BlogList>}
        </div>
    );
};

export default Home;
